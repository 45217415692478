import React from 'react';
import { useTranslation } from 'react-i18next';
import '../style/FailStyle.scss'

function FailPayment() {
  const { t } = useTranslation()

  return (
    <div className="container min-vh-100 py-4 d-flex justify-content-center">
      <div className="d-flex flex-column w-100 form-payment">
        <div className="logo d-flex align-items-center justify-content-center w-100 mb-5">
          <img src="/h2h_logo.png" className="logo" alt="logo" />
        </div>
        <div className="container-payment-failure d-flex flex-column align-items-center justify-content-center">
          <div className="box-error d-flex flex-column align-items-center justify-content-center">
            <img src="/error-icon.svg" alt="icon" className="icon-status" />
            <div className="text-fail text-center mt-2">{t('invalid_access')}</div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default FailPayment;