import { CardNumberElement, CardExpiryElement, CardCvcElement, useElements, useStripe } from '@stripe/react-stripe-js';
import QueryString from 'query-string';
import { useEffect, useState } from 'react';
import axiosClient from './apis/axiosClient';
import { requestApis } from './apis/request';
import FailPayment from './components/FailPayment';
import FormPayment from './components/FormPayment';
import ListPackage from './components/ListPackage';
import Loading from './components/Loading';
import PaymentSuccess from './components/PaymentSuccess';
import { NotificationManager } from 'react-notifications';

import "react-credit-cards/es/styles-compiled.css";
import "./style/AppStyle.scss";
import { useTranslation } from 'react-i18next';

const STATUS_PAGE = {
  LOADING: 1,
  VERIFIED: 2,
  FAIL: 3
}

// eslint-disable-next-line
// const testLink = (value) => /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/gm.test(value)

function App() {
  const { t } = useTranslation()
  const stripe = useStripe();
  const elements = useElements();
  const params = QueryString.parse(window.location.search)
  const { token, callback_url, x_api_token } = params
  const [currentPackage, setCurrentPackage] = useState(null)
  const [dataPreview] = useState({
		cvc: 'XXX',
		expiry: "",
		name: "Name on Card",
		number: "**** **** **** 1234"
  });
  const [isFetching, setIsFetching] = useState(true)
  const [focused, setFocused] = useState('')
  const [isConfirm, setIsConfirm] = useState(false)
  const [loading, setLoading] = useState(false)
  const [paymentSuccess, setPaymentSuccess] = useState(false)
  const [listPackage, setListPackage] = useState([])
  const [statusPage, setStatusPage] = useState(STATUS_PAGE.LOADING)
  const [profile, setProfile] = useState(null)
  const [brandCard, setBrandCard] = useState("unknown")

  const handleInputFocus = ({ target }) => {
    setFocused(target.name);
  };

  const onSubmit = (e) => {
    e.preventDefault()
    if (!stripe || !elements) {
      return;
    }
    setIsConfirm(true)
  }

  const clearDataStripe = (e) => {
    const cardNumberElement = elements.getElement(CardNumberElement);
    const cardExpiryElement = elements.getElement(CardExpiryElement);
    const cardCvcElement = elements.getElement(CardCvcElement);
    cardNumberElement.clear();
    cardExpiryElement.clear();
    cardCvcElement.clear();
    setCurrentPackage(null)
  }

  const onPayment = async () => {
    setLoading(true)
    const { point_type, point_amount, currency, amount, id } = currentPackage

    requestApis.buyPoint({
      point_type: point_type,
      point_amount: point_amount,
      currency: currency,
      amount: amount,
      point_package_id: id
    })
      .then(async (res) => {
        if (res.status === 200) {
          const { client_secret } = res.data
          return stripe.confirmCardPayment(client_secret, {
            payment_method: {
              type: 'card',
              card: elements.getElement(CardNumberElement)
            }
          })
        } else {
          setLoading(false)
          throw res
        }
      })
      .then((res) => {
        setLoading(false)
        if (res.error) {
          NotificationManager.error(t(`error_code:${res.error.code}`), t('error'))
        } else {
          setPaymentSuccess(true)
        }
      })
      .catch((err) => {
        if (err?.response?.data?.message === 'point_package_data_has_been_changed' || err?.response?.data?.message === 'point_package_not_found') {
          requestApis.getPointPackage().then((res) => {
            setListPackage(res.data.data)
            setIsFetching(false)
          }).catch((err) => {
            setStatusPage(STATUS_PAGE.FAIL)
          })
          NotificationManager.error(t(`error_code:${err?.response?.data?.message}`), t('error'))
        } else {
          const messageError = err?.response?.data?.code || err?.code || err?.message || 'system_error'
          NotificationManager.error(t(`error_code:${messageError}`), t('error'))
        }
        setLoading(false)
      })
  }

  const onRedirect = () => {
    window.location.href = callback_url
  }

  useEffect(() => {
    if (!callback_url || !token || !x_api_token) {
      setStatusPage(STATUS_PAGE.FAIL)
    }
    // else if (!testLink(callback_url)) {
    //   setStatusPage(STATUS_PAGE.FAIL)
    // }
    else {
      axiosClient.defaults.headers.common = {
        'Authorization': `Bearer ${token}`,
        'x-api-token': x_api_token
      }
      requestApis.checkToken()
        .then((res) => {
          if (res.status === 200) {
            setProfile(res.data)
            setStatusPage(STATUS_PAGE.VERIFIED)
            return requestApis.getPointPackage()
          } else {
            window.location.href = `${callback_url}?error=${res.error}`
          }
        })
        .then((res) => {
          setListPackage(res.data.data)
          setIsFetching(false)
        })
        .catch((err) => {
          setStatusPage(STATUS_PAGE.FAIL)
        })
    }
  }, [token, callback_url, x_api_token])

  if (statusPage === STATUS_PAGE.LOADING)
    return <Loading />
  
  if (statusPage === STATUS_PAGE.FAIL)
    return <FailPayment />
  
  if (paymentSuccess)
    return <PaymentSuccess onRedirect={onRedirect} />

  return (
    <div className="container min-vh-100 py-4 d-flex justify-content-center">
      <div className="form-payment">
        <div className="logo d-flex align-items-center justify-content-center w-100 mb-5">
          <img src="/h2h_logo.png" className="logo" alt="logo" />
        </div>
        <div className="two-form-content d-flex justify-content-between">
          <ListPackage
            isFetching={isFetching}
            currentPackage={currentPackage}
            setCurrentPackage={setCurrentPackage}
            listPackage={listPackage}
            clearDataStripe={clearDataStripe}
          />
          <FormPayment
            brandCard={brandCard}
            setBrandCard={setBrandCard}
            dataPreview={dataPreview}
            currentPackage={currentPackage}
            onSubmit={onSubmit}
            isConfirm={isConfirm}
            setIsConfirm={setIsConfirm}
            handleInputFocus={handleInputFocus}
            loading={loading}
            onPayment={onPayment}
            setCurrentPackage={setCurrentPackage}
            focused={focused}
            profile={profile}
          />
        </div>
      </div>
    </div>
  );
}

export default App;
