import cx from 'classnames';
import { useTranslation } from "react-i18next";
import "../style/LanguageStyle.scss";
import LocalStorage from "../utils/storage";

const languages = [
  {
    code: 'en',
    icon: '/flags/uk.svg'
  },
  {
    code: 'jp',
    icon: '/flags/jp.svg'
  }
]

function MultiLanguages() {
  const { i18n } = useTranslation()

  const onChangeLanguage = (lang) => {
    i18n.changeLanguage(lang)
    LocalStorage.set('lang', lang)
  }

  return (
    <div className="box-language">
      <div className="icon__flag-active">
        {
          languages.map((lan) => (
            <img
              onClick={() => onChangeLanguage(lan.code)}
              key={lan.code}
              className={cx("shadow", {
                active: lan.code === i18n.language
              })}
              src={lan.icon} alt="flag" />
          ))
        }
      </div>
    </div>
  );
}

export default MultiLanguages;