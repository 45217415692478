import React from 'react';
import { Button, Col, Form } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import '../style/SuccessStyle.scss'

function PaymentSuccess({ onRedirect }) {
  const { t } = useTranslation()
  const onReload = () => {
    window.location.reload()
  }

  return (
    <div className="container min-vh-100 py-4 d-flex justify-content-center">
      <div className="d-flex flex-column w-100 form-payment">
        <div className="logo d-flex align-items-center justify-content-center w-100 mb-5">
          <img src="/h2h_logo.png" className="logo" alt="logo" />
        </div>
        <div className="container-payment-success d-flex flex-column align-items-center justify-content-center">
          <div className="box-success d-flex flex-column align-items-center justify-content-center">
            <img src="/success.svg" alt="icon" className="icon-status" />
            <div className="text-white text-center mt-2">{t('payment_success')}</div>
            <Form.Row className="mt-4">
              <Form.Group as={Col}>
                <Button
                  onClick={onReload}
                  variant="primary"
                  className="btn-keep-buy"
                >
                  {t('keep_buying')}
                </Button>
              </Form.Group>
              <Form.Group as={Col} className="text-nowrap">
                <Button
                  onClick={onRedirect}
                  className="btn-redirect"
                >
                  {t('redirect_now')}
                </Button>
              </Form.Group>
            </Form.Row>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PaymentSuccess;