import 'bootstrap/dist/css/bootstrap.min.css';
import React, { useMemo } from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import reportWebVitals from './reportWebVitals';
import MultiLanguages from "./components/MultiLanguages";
import { useTranslation } from 'react-i18next';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import { NotificationContainer } from 'react-notifications';

import 'react-notifications/lib/notifications.css';
import './locales/i18n'
import './index.css';

const stripeAPIKey = process.env.REACT_APP_STRIPE_KEY

const Init = () => {
  const { i18n } = useTranslation()
  const stripePromise = useMemo(() => loadStripe(stripeAPIKey), []);

  return (
    <Elements key={i18n.language} stripe={stripePromise} options={{ locale: i18n.language === 'jp' ? 'ja' : 'en' }}>
      <MultiLanguages />
      <NotificationContainer />
      <App />
    </Elements>
  )
}

ReactDOM.render(
  <React.StrictMode>
    <Init />
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
