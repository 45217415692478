import { CardCvcElement, CardExpiryElement, CardNumberElement, useStripe } from '@stripe/react-stripe-js';
import cx from 'classnames';
import React, { useMemo, useState } from 'react';
import { Button, Col, Form, Spinner } from 'react-bootstrap';
import Cards from 'react-credit-cards';
import { useTranslation } from 'react-i18next';
import useResponsiveFontSize from '../hooks/useResponsiveFontSize';

const useOptions = () => {
  const fontSize = useResponsiveFontSize();
  const options = useMemo(
    () => ({
      style: {
        base: {
          fontSize,
          color: "#ffffff",
          letterSpacing: "0.025em",
          fontFamily: "Source Code Pro, monospace",
          "::placeholder": {
            color: "#6C6975"
          },
        },
        invalid: {
          color: "#9e2146"
        }
      }
    }),
    [fontSize]
  );

  return options;
};

function FormPayment({
  currentPackage, onSubmit, isConfirm, setIsConfirm, handleInputFocus, loading,
  onPayment, focused, dataPreview, profile, brandCard, setBrandCard
}) {
  const options = useOptions();
  const stripe = useStripe();
  const [numberErr, setNumberErr] = useState({})
  const [exprErr, setExprErr] = useState({})
  const [cvcErr, setCvcErr] = useState({})
  const { t } = useTranslation()

  return (
    <div
      className={cx("payment-info transition-field form-payment-container", {
        hidden: !currentPackage,
        'width-form-payment': currentPackage
      })}
    >
      <div className="w-100 mb-5 box-title-form-payment">
          <h5 className="text-center">
            <b>
              {t('payment_detail')}
            </b>
          </h5>
      </div>
      <Cards
        cvc={dataPreview.cvc}
        expiry={dataPreview.expiry}
        focused={focused}
        number={dataPreview.number}
        name={dataPreview.name}
        issuer={brandCard}
        preview={true}
      />
      <form className="mt-5"
        onSubmit={onSubmit}>
        <Form.Group className="field">
          <label>{t('email')}</label>
          <div>
            <Form.Control
              className={"input-style disabled"}
              value={profile?.email}
              disabled
              readOnly
            />
          </div>
          <div className="space-1" />
        </Form.Group>
        <Form.Group className="field">
          <label>{t('card_number')}</label>
          <CardNumberElement
            options={{
              ...options,
              disabled: isConfirm
            }}
            onChange={event => {
              setBrandCard(event.brand)
              setNumberErr(!event.complete ? {
                ...event.error
              } : null)
            }}
            onFocus={() => handleInputFocus({ target: { name: 'number' }})}
            className={cx("input-style StripeElement", {
              disabled: isConfirm
            })}
          />
          <p className="text-error space-1">{numberErr ? numberErr.message : ''}</p>
        </Form.Group>
        <Form.Row>
          <Form.Group as={Col}>
            <label>{t('expiration_date')}</label>
            <CardExpiryElement
              options={{
                ...options,
                disabled: isConfirm
              }}
              onChange={event => {
                setExprErr(!event.complete ? {
                ...event.error
              } : null)
              }}
              onFocus={() => handleInputFocus({ target: { name: 'expiry' }})}
              className={cx("input-style StripeElement", {
                disabled: isConfirm
              })}
            />
            <p className="text-error space-2">{exprErr ? exprErr.message : ''}</p>
          </Form.Group>
          <Form.Group as={Col}>
            <label>{t('cvc')}</label>
            <CardCvcElement
              options={{
                ...options,
                disabled: isConfirm,
                placeholder: 'CVC'
              }}
              onChange={event => {
                setCvcErr(!event.complete ? {
                ...event.error
              } : null)
              }}
              onFocus={() => handleInputFocus({ target: { name: 'cvc' }})}
              className={cx("input-style StripeElement", {
                disabled: isConfirm
              })}
            />
            <p className="text-error space-2">{cvcErr ? cvcErr.message : ''}</p>
          </Form.Group>
        </Form.Row>
        {!isConfirm ? (
          <Button
            disabled={!stripe || !!numberErr || !!exprErr || !!cvcErr}
            className={cx("d-block w-100 btn-submit", {
              disabled: !stripe || !!numberErr || !!exprErr || !!cvcErr
            })}
            variant="dark"
            type="submit">
            {t('next')}
          </Button>
        ) : (
          <Form.Row>
            <Form.Group as={Col} className="mb-0">
              <Button
                disabled={loading}
                onClick={() => setIsConfirm(false)}
                className="d-block w-100 btn-back">
                {t('back')}
              </Button>
            </Form.Group>
            <Form.Group as={Col} className="mb-0">
              <Button
                disabled={loading}
                onClick={onPayment} className="d-block w-100 btn-confirm"
              >
                {loading ? <Spinner animation="border" role="status" size="sm" /> : t('pay')}
              </Button>
            </Form.Group>
          </Form.Row>
        )}
      </form>
    </div>
  );
}

export default FormPayment;