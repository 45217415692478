import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import LocalStorage from '../utils/storage'
import en from './en.json'
import jp from './jp.json'
import errorEn from './errrorEn.json'
import errorJp from './errorJp.json'

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources: {
      en: {
        translations: en,
        error_code: errorEn
      },
      jp: {
        translations: jp,
        error_code: errorJp
      }
    },
    lng:
      LocalStorage.has('lang') && LocalStorage.get('lang') === 'en'
        ? 'en'
        : 'jp',
    fallbackLng: ['jp', 'en'],
    debug: false,
    ns: ['translations', 'error_code'],
    defaultNS: 'translations',
    interpolation: {
      escapeValue: false,
      formatSeparator: ','
    },
    react: {
      wait: true
    }
  })

export default i18n
