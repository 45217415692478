import axiosClient from "./axiosClient";

const requestApis = {
  checkToken: () => axiosClient.get('/api/v1/oauth/users/profile'),
  getPointPackage: () => axiosClient.get('/api/v1/client/crypto-stakes-point-package', {
    params: {
      page: 1,
      limit: 1000
      }
  }),
  buyPoint: (payload) => axiosClient.post('/api/v1/client/point/buy', payload)
}

export {
  requestApis
}