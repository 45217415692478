import React from 'react';
import cx from 'classnames'
import { AiOutlineLeft } from 'react-icons/ai';
import { Spinner } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { numberPoint } from '../utils/tools';

function ListPackage({ listPackage, currentPackage, setCurrentPackage, isFetching, clearDataStripe }) {
  const { t } = useTranslation()

  return (
    <div className={cx("transition-field", {
      'width-form-point': currentPackage,
      'form-full-width': !currentPackage
      })}
    >
      <div className="package-form-content point-package h-100">
        <div className={cx('mt-3 list-package-point', {
          'd-none': currentPackage
        })}>
          {!currentPackage && <h5 className="text-center mt-3 mb-4">{t('choose_package')}</h5>}
          {isFetching ? (
            <div className="fetching-data d-flex justify-content-center align-items-center">
              <Spinner animation="border" role="status" size="lg" />
            </div>
          ) : (
            <div className="row mx-lg-n3 mx-md-n2 fetching-data">
              {
                listPackage.length !==0 ? listPackage.map((e, index) => (
                  <div
                    onClick={() => setCurrentPackage(e)}
                    className="height-fit-content cursor-pointer col col-md-4 col-lg-3 col-sm-6 col-12 mb-3 px-lg-3 px-md-2"
                    key={index}
                  >
                    <div className="point-item-border">
                      <div className="point-item p-2">
                        <div className="text-point"><b>{numberPoint(e.point_amount)}</b> {t('points')}</div>
                        <div className="d-flex justify-content-center my-2">
                          <img src="/coin-r.png" alt="Coin" />
                        </div>
                        <div className="text-money"><b>{numberPoint(e.amount)}</b> 円</div>
                      </div>
                    </div>
                  </div>
                )) : (
                  <div className="d-flex justify-content-center align-items-center flex-column w-100">
                    <img src="/box.svg" className="box-empty mb-5" alt="" />
                    <b>{t('no_available_package')}</b>
                    <p>{t('comeback_later')}</p>
                  </div>
                )
              }
            </div>
          )}
        </div>
        <div className={cx("current-package h-100", {
          'd-none': !currentPackage
        })}>
          <div className="d-flex align-items-center w-100">
            <div
              onClick={clearDataStripe}
              role="button"
              className="box-space d-flex align-items-center">
              <AiOutlineLeft className="icon" />
            </div>
            <h5 className="text-center flex-grow-1 mb-0 title-package-mobile">
              <b>
                {t('payment_detail')}
              </b>
            </h5>
            <h5 className="text-center flex-grow-1 mb-0 title-package">
              <b>
                {t('point_package')}
              </b>
            </h5>
            <div className="box-space" />
          </div>
          <div className="current-point h-100">
            <div className="text-point text-center w-100"><b>{numberPoint(currentPackage?.point_amount)}</b> <small>{t('points')}</small></div>
            <img className="my-3" src="/coin-r.png" alt="Coin" />
            <div className="total-pay text-center">
              {numberPoint(currentPackage?.amount)} 円
            </div>
          </div>
          <div className="current-point-mobile h-100">
            <img className="my-3" src="/coin-r.png" alt="Coin" />
            <div>
              <div className="text-point w-100"><b>{numberPoint(currentPackage?.point_amount)}</b> <small>{t('points')}</small></div>
              <div className="total-pay">
                {numberPoint(currentPackage?.amount)} 円
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ListPackage;